import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../../assets/images/services/services-details1.jpg'
import project2 from '../../../assets/images/projects/project2.jpg'
import charts from '../../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Soluții personalizate</span>
                            <h3>Despre software dezvoltat la comandă</h3>
                            <p>La Vertical Digital, ne specializăm în dezvoltarea de soluții software personalizate pentru a satisface nevoile unice ale clienților noștri. Cu o echipă de dezvoltatori de software experimentați și un istoric dovedit de proiecte reușite, vă putem ajuta să transformați ideile în realitate.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Procesul nostru</h3>
                                        <ul>
                                            <li>Faza de descoperire</li>
                                            <li>Design</li>
                                            <li>Implementare</li>
                                            <li>Lansare</li>
                                            <li>Monitorizare & Reglare</li>
                                            <li>Funcții noi, îmbunătățiri & întreținere</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>Procesul nostru de dezvoltare de software personalizat este proiectat să fie eficient, transparent și colaborativ. Începem prin a lucra cu dvs. pentru a înțelege cerințele și obiectivele dvs. și apoi cream un plan detaliat pentru a ne asigura că livrăm o soluție care să vă satisfacă nevoile.</p>
                            <h3>Arii de aplicabilitate</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Producție
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Sănătate
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automotive
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Imobiliare
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistică
                                    </div>
                                </div>
                            </div>

                            <h3>Tehnologii folosite de noi</h3>
                            <ul className="technologies-features">
                                <li><span>Java</span></li>
                                <li><span>JavaScript</span></li>
                                <li><span>Python</span></li>
                                <li><span>PHP</span></li>
                                <li><span>Swift</span></li>
                                <li><span>C# (C- Sharp)</span></li>
                                <li><span>Kotlin</span></li>
                                <li><span>SQL</span></li>
                                <li><span>Docker</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent