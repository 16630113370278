import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import ServiceDetailsContent from '../../components/servicii/custom-sw/ServiceDetailsContent'
import RelatedServices from '../../components/servicii/custom-sw/RelatedServices'
 
const Details = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Dezvoltare de Software la Comandă"
                homePageText="Servicii"
                homePageUrl="/servicii/"
                activePageText="Dezvoltare de Software la Comandă"
            />
            <ServiceDetailsContent />
            <RelatedServices />
            <Footer />
        </Layout>
    );
}

export default Details